import React from "react"
import ThemeWrapper from "../theme/themeWrapper"
import Footer from "./footer"
import Header from "./header"

const WrapRoot: React.FC = ({ children }) => (
  <ThemeWrapper>
    <Header />
    {children}
    <Footer />
  </ThemeWrapper>
)

export default WrapRoot
