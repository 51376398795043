import { useState } from "react"

export type ReturnObject = {
  handleOpen: () => void
  handleClose: () => void
  toggle: () => void
}

export type BoolState = (init?: boolean) => [boolean, ReturnObject]

const useBoolState: BoolState = (init = false) => {
  const [open, setOpen] = useState(init)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)
  const toggle = () => setOpen(s => !s)

  return [open, { handleOpen, handleClose, toggle }]
}

export default useBoolState
