import { fade } from "@material-ui/core/styles/colorManipulator"
import { createGlobalStyle, css } from "styled-components"

export const heightTransition = css`
  transition: height ${({ theme }) => theme.transitions.duration.complex}ms
    ease-in-out;
`

export const borderRadius = css`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
`

export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => fade(theme.palette.primary.light, 0.14)};
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }

  .inline-gatsby-image-wrapper {
    ${borderRadius};
  }

  //
  // scrollbar styling
  //
  * {
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => theme.palette.primary.dark};
  }
  *::-webkit-scrollbar {
    width: ${({ theme }) => `${theme.spacing(2)}px`};
  }
  *::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.primary.light};  
  }
  *::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.primary.dark};   
    border: ${({ theme }) => `${theme.spacing(0.5)}px`} solid ${({ theme }) =>
  theme.palette.primary.light}; 
    border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};    
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    *::-webkit-scrollbar {
      width: 0;
    }
  }
`
