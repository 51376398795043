import { MuiThemeProvider } from "@material-ui/core"
import responsiveFontSizes, {
  ResponsiveFontSizesOptions,
} from "@material-ui/core/styles/responsiveFontSizes"
import React from "react"
import { ThemeProvider } from "styled-components"
import useTheme from "./useTheme"

const options: ResponsiveFontSizesOptions = {
  factor: 5,
}

const Theme: React.FC = ({ children }) => {
  const theme = useTheme()
  const resTheme = responsiveFontSizes(theme, options)

  return (
    <MuiThemeProvider theme={resTheme}>
      <ThemeProvider theme={resTheme}>{children}</ThemeProvider>
    </MuiThemeProvider>
  )
}

export default Theme
