import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"
import useContactInfo from "../../hooks/useContactInfo"
import Spacer from "../base/layout/spacer"
import Text from "./text"

const Wrapper = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacing(1.5)}px ${theme.spacing(3)}px ${theme.spacing(2)}px`};
  display: flex;
  flex-direction: column;
  > * {
    padding-left: ${({ theme }) => theme.spacing(1)}px;
  }
`

const A = styled(Link)`
  font-size: 1.1rem;
`

const ContactData: React.FC = () => {
  const { naw, email, formatted } = { ...(useContactInfo() || {}) }

  return (
    <Wrapper>
      <Typography variant="h6" component="p" color="primary" gutterBottom>
        Contact
      </Typography>
      <Text component="pre" dangerouslySetInnerHTML={{ __html: String(naw) }} />
      <Spacer minHeight={16} />
      <A href={formatted?.email?.emailUri}>{email}</A>
      <Spacer minHeight={8} />
      <A href={formatted?.number?.phoneUri}>{formatted.number.nicePhone}</A>
    </Wrapper>
  )
}

export default ContactData
