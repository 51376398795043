import { createMuiTheme } from "@material-ui/core"

const useTheme = () => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#6AA863",
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: "#040218",
      },
    },
    typography: {
      fontFamily: `"Open Sans", Arial, sans-serif`,
      h1: {
        fontSize: "2.67rem",
      },
      h2: {
        fontSize: "1.7rem",
      },
      h3: {
        fontSize: "1.4rem",
        fontWeight: 300,
      },
      h4: {
        fontSize: "1.4rem",
        fontWeight: 300,
      },
      h5: {
        fontSize: "1.3rem",
      },
      h6: {
        fontSize: "1.1rem",
      },
    },
    props: {
      MuiPaper: {
        elevation: 3,
      },
    },
  })

  return theme
}

export default useTheme
