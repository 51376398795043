import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"

const Text = styled(props => <Typography {...props} />)`
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  padding-top: ${({ theme }) => theme.spacing(1.5)}px;
  font-weight: 300;
`

export default Text
