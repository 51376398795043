import Link from "@material-ui/core/Link"
import SvgIcon from "@material-ui/core/SvgIcon"
import Toolbar from "@material-ui/core/Toolbar"
import MailIcon from "@material-ui/icons/Mail"
import PhoneIcon from "@material-ui/icons/Phone"
import React from "react"
import styled from "styled-components"
import useContactInfo from "../../hooks/useContactInfo"

const ContactBar = styled(Toolbar)`
  min-height: ${({ theme }) => theme.spacing(4.5)}px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  justify-content: flex-end;
`

const A = styled(Link)`
  color: inherit;
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing(2)}px;

  & svg {
    margin-right: ${({ theme }) => theme.spacing(0.5)}px;
  }
`

interface IconProps {
  icon: React.ReactNode
}
const Icon: React.FC<IconProps> = ({ icon }) => (
  <SvgIcon children={icon} color="inherit" fontSize="small" />
)

interface IconLinkProps {
  href: string
  icon: React.ReactNode
}
const IconLink: React.FC<IconLinkProps> = ({ href, icon, children }) => (
  <A href={href}>
    <Icon icon={icon} />
    {children}
  </A>
)

const Contact: React.FC = () => {
  const { email, number, formatted } = { ...(useContactInfo() || {}) }
  return (
    <ContactBar disableGutters>
      {email ? (
        <IconLink
          href={formatted?.email?.emailUri}
          icon={<MailIcon />}
          children={email}
        />
      ) : null}
      {number ? (
        <IconLink
          href={String(formatted?.number?.phoneUri)}
          icon={<PhoneIcon />}
          children={formatted?.number?.nicePhone}
        />
      ) : null}
    </ContactBar>
  )
}

export default Contact
